<template>
  <transition name="slide-up">
    <div class="success-message-div" @click="preventNormal($event)">
      <div class="success-bg">
        <div class="msg-content">
          <img src="/img/onboarding/checkout.svg" style="margin-right: 8px" />
          <div class="success-msg">{{ successMsg }}</div>
          <img
            src="/img/onboarding/cancel_close.svg"
            style="cursor: pointer; margin-left: 48px"
            @click="clearSuccessMsg"
          />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: ["time"],
  mounted() {
    let valSec = 7000;
    setTimeout(() => this.clearSuccessMsg(), valSec);
  },
  methods: {
    ...mapActions("alertMsg", ["clearSuccessMsg"]),
    preventNormal(event) {
      event.stopPropagation();
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg"]),
    clearMsg() {
      if (this.time) {
        let valSec = +this.time * 1000;
        setTimeout(() => this.clearSuccessMsg(), valSec);
      }
    },
  },
};
</script>
<style scoped>
.success-message-div {
  position: fixed;
  /* width: 210px; */
  top: 6%;
  right: 3%;
  /* padding-left: 232px; */
  z-index: 10000;
  background: #ffffff;
  border: 1px solid #1fa564;
  box-sizing: border-box;
  border-radius: 4px;
}
.success-bg {
  background: rgba(84, 207, 148, 0.1);
  padding: 16px;
}
.msg-content {
  display: flex;
  max-width: 374px;
}
.success-msg {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #1fa564;
  padding-top: 2px;
}
</style>