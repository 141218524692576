<template>
  <transition name="slide-up">
    <div class="error-message-div">
      <div class="error-bg">
        <div class="msg-content">
          <img
            src="/img/onboarding/error-triangle.svg"
            style="margin-right: 8px"
          />
          <div class="error-msg">{{ errorMsg }}</div>
          <img
            src="/img/onboarding/cancel_close.svg"
            style="cursor: pointer; margin-left: 48px"
            @click="clearErrorMsg"
          />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: ["time"],
  mounted() {
    let valSec = 7000;
    setTimeout(() => this.clearErrorMsg(), valSec);
  },
  methods: {
    ...mapActions("alertMsg", ["clearErrorMsg"]),
  },
  computed: {
    ...mapState("alertMsg", ["errorMsg"]),
    clearMsg() {
      if (this.time) {
        let valSec = +this.time * 1000;
        setTimeout(() => this.clearErrorMsg(), valSec);
      }
    },
  },
};
</script>
<style scoped>
.error-message-div {
  position: fixed;
  /* width: 210px; */
  top: 6%;
  right: 3%;
  z-index: 10000;
  border: 1px solid #a5271f;
  box-sizing: border-box;
  border-radius: 4px;
  background: #ffffff;
}
.error-bg {
  padding: 16px;
  background: rgba(212, 91, 84, 0.1);
}
.msg-content {
  display: flex;
  max-width: 374px;
}
.error-msg {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #a5271f;
  padding-top: 2px;
}
</style>