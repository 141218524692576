var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-up" } }, [
    _c(
      "div",
      {
        staticClass: "success-message-div",
        on: {
          click: function ($event) {
            return _vm.preventNormal($event)
          },
        },
      },
      [
        _c("div", { staticClass: "success-bg" }, [
          _c("div", { staticClass: "msg-content" }, [
            _c("img", {
              staticStyle: { "margin-right": "8px" },
              attrs: { src: "/img/onboarding/checkout.svg" },
            }),
            _c("div", { staticClass: "success-msg" }, [
              _vm._v(_vm._s(_vm.successMsg)),
            ]),
            _c("img", {
              staticStyle: { cursor: "pointer", "margin-left": "48px" },
              attrs: { src: "/img/onboarding/cancel_close.svg" },
              on: { click: _vm.clearSuccessMsg },
            }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }