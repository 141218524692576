const appMixin = {
  data() {
    return {};
  },
  methods: {
    compareTwoDates(dueDate) {
      return this.$moment(dueDate, "YYYY-MM-DD").diff(
        this.$moment(new Date()),
        "days"
      );
    },
    subtractDaysFromDate(date, days) {
      let resultDate = this.$moment(date, "YYYY-MM-DD").subtract(days, "days");
      return resultDate.format("Do MMM");
    },
    addDaysFromDate(date, days) {
      let resultDate = this.$moment(date, "YYYY-MM-DD").add(days, "days");
      return resultDate.format("Do MMM");
    },
    teamFrameWork(members) {
      let frameWork = [];
      if (members) {
        members.map((member) => {
          let formatTools = JSON.parse(member.language_tools);
          if (formatTools !== null && formatTools.length != 0) {
            frameWork = [...frameWork, ...formatTools];
          }
        });
        let teamStack = [...new Set(frameWork)];
        return teamStack;
      }
    },
    developerStacks(member) {
      let frameWork = [];
      if (member) {
        // members.map((member) => {
        if (
          member.framework_tools !== null &&
          member.framework_tools.length != 0
        ) {
          frameWork = [...frameWork, ...member.framework_tools];
        }
        // });
        let teamStack = [...new Set(frameWork)];
        return teamStack;
      }
    },
    imageExists(src, good, bad) {
      var img = new Image();
      img.onload = good;
      img.onerror = bad;
      img.src = src;
    },
    parseToJson(data) {
      try {
        const result = JSON.parse(data);
        const isArray = Array.isArray(result);
        if (isArray) {
          return result;
        }
      } catch (error) {
        this.$developConsole(error);
      }
      return [];
    },
    percentageTasksDone(work) {
      let done = work.filter((task) => task.task_status_id == 5);
      let completed = done.length;
      let totalWork = work.length;
      let ratio = completed / totalWork;
      return Math.round(ratio * 100) || 0;
    },
    replaceUnderscore(name) {
      let final;
      if (name.includes("_")) {
        let result = name.split("_");
        final = result.join("-");
      } else {
        final = name;
      }
      return final;
    },
    actionPerformed(action) {
      switch (action) {
        case "move":
          return 1;
        case "add":
          return 2;
        case "completed":
          return 3;
        case "assign":
          return 4;
        case "mention":
          return 5;
      }
    },
    currentDate() {
      return this.$moment()
        .utcOffset(0, false)
        .format();
    },
    ratingScoreEmoji(num) {
      if (num == 5) {
        return "smiling";
      } else if (num < 5 && num >= 4) {
        return "good";
      } else if (num < 4 && num >= 3) {
        return "neutral";
      } else if (num < 3 && num >= 2) {
        return "boring";
      } else if (num < 2) {
        return "sad";
      }
    },
    dateDifference(date1, date2) {
      const a = this.$moment(date1);
      const b = this.$moment(date2);
      let years = a.diff(b, "year");
      b.add(years, "years");
      let months = a.diff(b, "months");
      b.add(months, "months");
      return `${years}yrs ${months}m`;
    },
    changeTimezone(date, tzString) {
      return new Date(
        (typeof date === "string"
          ? new Date(date)
          : date
        ).toLocaleString("en-US", { timeZone: tzString })
      );
    },
  },
};

export default appMixin;
